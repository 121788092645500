// 头部登陆注册导航栏
<template>
  <div class="header_wrap">
    <div class="header">
      <div class="header_left">
        <span class="hello">{{L['您好，欢迎来到']}}{{configInfo.data.basic_site_name}}</span>
        <template v-if="loginFlag">
          <img :src="grade.data.gradeLogo" alt="" v-if="grade.data.gradeLogo" class="gradeLogo_img">
          <span class="register h1"
            @click="goToByPush('/member/index')">{{memberInfo.memberNickName||memberInfo.memberName}}</span>
          <span class="register h1" v-if="multipleAccounts && multipleAccounts.length>0" @click="toggleMultipleAccounts()">[{{L['切换账号']}}]</span>
          <transition name="slide-fade">
            <div v-if="showMultipleAccounts" class="member-list-container">
              <button class="close-button" @click="toggleMultipleAccounts()">关闭</button>
              <br/>
              <ul>
                <li v-for="(member, index) in multipleAccounts" :key="index" @click="selectMember(member)">
                  {{ member.memberName }}
                </li>
              </ul>
            </div>
          </transition>
          <div v-if="showLoading" class="loading-overlay show">
            <div class="loading-text">切换账号中...</div>
          </div>
          <span class="register h1 login_out" @click="loginOut()">[{{L['退出']}}]</span>
        </template>

        <template v-if="!loginFlag">
          <span class="register h1" @click="goToByPush('/login')">{{L['登录']}}</span>
          <span class="register h1" @click="goToByPush('/register')">{{L['注册']}}</span>
        </template>
      </div>
      <div class="header_right">
        <ul>
          <li @click="goToByPush('/index',{})">
            <div class="li_item">{{L['商城首页']}}</div>
          </li>
          <li>
            <div class="has_more li_item">
              {{L['我的订单']}}
              <div class="li_item_more">
               <a href="javascript:void(0)" class="li_item_more_item"
                    @click="goToByPush('/member/order/list')">{{L['全部订单']}}</a>
                <a href="javascript:void(0)" class="li_item_more_item"
                  @click="goToByPush('/member/order/list',{'orderState':10})">{{L['待支付订单']}}</a>
                <a href="javascript:void(0)" class="li_item_more_item"
                @click="goToByPush('/member/order/list',{'orderState':20})">{{'待发货订单'}}</a>
                <a href="javascript:void(0)" class="li_item_more_item"
                  @click="goToByPush('/member/order/list',{'orderState':30})">{{L['待收货订单']}}</a>
                <a href="javascript:void(0)" class="li_item_more_item"
                  @click="goToByPush('/member/order/evaluation')">{{L['待评价订单']}}</a>
              </div>
            </div>
          </li>
          <li @click="goToByPush('/member/index')">
            <div class="li_item">{{L['个人中心']}}</div>
          </li>
          <li>
            <div class="has_more li_item">
              {{L['我的收藏']}}
              <div class="li_item_more">
                <a href="javascript:void(0)" class="li_item_more_item"
                  @click="goToByPush('/member/collect')">{{L['商品收藏']}}</a>
                <a href="javascript:void(0)" class="li_item_more_item"
                  @click="goToByPush('/member/collect',{type:'store'})">{{L['店铺收藏']}}</a>
                <a href="javascript:void(0)" class="li_item_more_item"
                  @click="goToByPush('/member/footprint')">{{L['我的足迹']}}</a>
              </div>
            </div>
          </li>
          <li>
            <div class="has_more li_item">
              {{L['我的钱包']}}
              <div class="li_item_more">
                <a href="javascript:void(0)" class="li_item_more_item"
                  @click="goToByPush('/member/coupon')">{{L['我的优惠券']}}</a>
                <a href="javascript:void(0)" class="li_item_more_item"
                  @click="goToByPush('/member/balance')">{{L['我的余额']}}</a>
              </div>
            </div>
          </li>
          <li @click="goToByPush('/article',{categoryId:58})">
            <div class="li_item">{{L['服务中心']}}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { useRouter } from "vue-router";
  import { useStore } from "vuex";
  import { getCurrentInstance, reactive, ref } from "vue";
  export default {
    name: "NavTopBar",
    setup() {
      const { proxy } = getCurrentInstance();
      const store = useStore();
      const L = proxy.$getCurLanguage();
      const memberInfo = store.state.memberInfo;
      const loginFlag = store.state.loginFlag;
      const router = useRouter();
      const configInfo = reactive({ data: {} })
      const grade = reactive({data:{}})
      const multipleAccounts = store.state.multipleAccounts;
      const showMultipleAccounts = ref(false); // 控制会员列表的显示与隐藏
      const showLoading= ref(false); // 新增：控制“切换账号中...”提示的显示

      //去注册
      const goRegister = () => {
        router.push({
          path: `/register`,
          query: {
            redirect: encodeURIComponent(window.location.href)
          }
        });
      };
      // 获取系统配置信息
      const getSystemConfigInfo = () => {
        proxy
          .$get("/v3/system/front/setting/getSettings", {
            names: "main_site_logo,main_user_center_logo,main_user_logon_bg,main_user_register_bg,pc_home_bottom_adv,main_user_forget_password_bg,basic_site_name,basic_site_icp,basic_site_copyright,basic_site_technical_support"
          })
          .then(res => {
            if (res.state == 200) {
              configInfo.data = {
                main_site_logo: res.data[0],
                main_user_center_logo: res.data[1],
                main_user_logon_bg: res.data[2],
                main_user_register_bg: res.data[3],
                pc_home_bottom_adv: res.data[4],
                main_user_forget_password_bg: res.data[5],
                basic_site_name: res.data[6],
                basic_site_icp: res.data[7],
                basic_site_copyright: res.data[8],
                basic_site_technical_support: res.data[9],
              }
              store.commit('updateConfigInfo', configInfo.data);
            }
          })
          .catch(() => {
            //异常处理
          });
      };
      const gettouxiang = ()=>{
        proxy
          .$post("/v3/member/front/member/getGrade")
          .then(res => {
            if (res.state == 200) {
              grade.data = res.data
            }
          })
          .catch(() => {
            //异常处理
          });
      }
      getSystemConfigInfo();
      gettouxiang();
      //前往具体的页面，type为具体的页面地址，param为参数对象
      const goToByPush = (type, param = {}) => {
        router.replace({
          path: type,
          query: param
        });
      };
      const toggleMultipleAccounts = () => {
        showMultipleAccounts.value = !showMultipleAccounts.value;
      }

      const selectMember = (member) => {
        showMultipleAccounts.value = !showMultipleAccounts.value;
        showLoading.value = true;
        let param = {};
        param.memberId =member.memberId;
        proxy.$get("v3/member/front/multipleAccounts/loginMember", param).then(res => {
          if (res.state == 200) {
            store.commit("clearAllData"); //清除数据
            //将用户信息存缓存，并跳转到首页
            localStorage.setItem("access_token", res.data.access_token);
            localStorage.setItem("refresh_token", res.data.refresh_token);
            localStorage.setItem("time", new Date().getTime().toString()); //存储refresh_token更新时间
            //获取用户信息，并同步信息到vuex
            proxy.$get("/v3/member/front/member/getInfo").then(res => {
              if (res.state == 200) {
                let params = {
                  memberId: res.data.memberId, //会员id
                };
                proxy.$get("/v3/member/front/multipleAccounts/list",params).then(multiAccRes => {
                  if (multiAccRes.state == 200) {
                    store.commit("updateMultipleAccounts", multiAccRes.data);
                    store.commit("updateMemberInfo", res.data);
                  }else {
                    store.commit("updateMemberInfo", res.data);
                  }
                  showLoading.value = false;
                  // 所有操作完成后，刷新页面
                  location.reload();
                })
              }
            });
          } else {
            console.error(res.msg);
          }
        });
      };

      //退出登录
      const loginOut = () => {
        store.commit("clearAllData"); //清除数据
        // proxy.$socket.disconnect()
        goToByPush("/login", {});      //退出登陆后跳转到登录页面
      };
      return { L, goRegister, memberInfo, loginOut, loginFlag, goToByPush, configInfo,grade, multipleAccounts, toggleMultipleAccounts,  showMultipleAccounts, selectMember, showLoading };
    }
  };
</script>

<style lang="scss" scoped>
  .header_wrap {
    width: 100%;
    height: 33px;
    background: #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: center;

    .header {
      width: 1210px;
      height: 33px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header_left {
        height: 100%;
        font-size: 12px;
        color: #999;
        line-height: 36px;
        display: flex;

        .hello {
          margin-right: 20px;
          color: #999;
        }

        .h1 {
          margin: 0 5px;
          cursor: pointer;

          &:hover {
            color: $colorMain;
          }
        }
        .member-list-container {
          position: absolute;
          background: linear-gradient(white, #f9f9f9); /* 渐变背景 */
          border: 1px solid rgba(0, 0, 0, 0.1); /* 半透明边框 */
          box-shadow:
              0 8px 20px rgba(0, 0, 0, 0.1),
              0 8px 20px rgba(0, 0, 0, 0.05);
          z-index: 1000;
          left: 27.5%;
          top: 3.6%;
          transition: all 0.3s ease; /* 过渡效果 */
          /* 其他样式根据需要添加 */
        }
        .slide-fade-enter-active, .slide-fade-leave-active {
          transition: all .3s ease;
        }
        .slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active below version 2.1.8 */ {
          transform: translateY(-20px);
          opacity: 0;
        }
        .member-list-container li:hover {
          /* 示例：改变背景色或文字颜色 */
          background-color: rgba(0,0,0,0.1);
          cursor: pointer;
        }
        .member-list-container li {
          border-bottom: 1px solid #f0f0f0;
          /* 允许文本换行 */
          word-wrap: break-word; /* 适用于老版本浏览器 */
          overflow-wrap: break-word; /* 现代浏览器的标准写法 */
          /* 限制宽度以确保文本在达到一定长度时换行 */
          max-width: 220px; /* 或设定一个具体的宽度值，比如200px */
          line-height: 2; /* 示例值，根据实际字体大小调整 */
          /* 移除上下内边距，保持文本紧密换行，但如果有需要保持列表项内部的垂直空白，可以适当调整 */
          padding: 0px 12px;
          margin-bottom: 10px;
        }
        .close-button {
          position: absolute; /* 使用绝对定位 */
          top: 6px; /* 距离顶部的距离，按需调整 */
          right: 10px; /* 距离右侧的距离，按需调整 */
          background-color: #f44336; /* 按钮背景颜色，示例为红色 */
          color: white; /* 文字颜色 */
          border: none; /* 移除边框 */
          cursor: pointer; /* 鼠标悬停时指针变为手型 */
          padding: 4px 10px; /* 内边距，按需调整 */
          font-size: 14px; /* 文字大小 */
          border-radius: 5px; /* 圆角效果 */
          outline: none; /* 移除点击时的轮廓 */
          opacity: 0.8; /* 设置透明度，让按钮看起来更柔和 */
          transition: opacity 0.3s ease; /* 平滑过渡效果 */
        }
        .close-button:hover {
          opacity: 1; /* 鼠标悬停时完全不透明 */
        }
        .loading-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
          color: #ffffff;
          z-index: 999;
          /* 添加过渡效果，使得出现和消失更加平滑 */
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }

        /* 当显示加载提示时应用此类来控制显示和动画 */
        .loading-overlay.show {
          opacity: 1;
        }

        /* 美化加载文字 */
        .loading-text {
          font-size: 32px; /* 增大字体大小 */
          font-weight: bold; /* 加粗字体 */
          text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); /* 文字阴影，增强立体感 */
          padding: 20px; /* 添加内边距，让文字不贴边 */
          animation: pulse 1s infinite; /* 添加脉冲动画效果 */
        }

        /* 定义脉冲动画 */
        @keyframes pulse {
          0% {opacity: 1;}
          50% {opacity: 0.7;}
          100% {opacity: 1;}
        }

      }

      .header_right {
        height: 100%;

        ul {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;

          .personal_center {
            width: 121px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          li {
            float: left;
            text-align: center;
            border-right: 1px solid #ddd;
            padding: 0 10px;
            height: 12px;

            .li_item {
              position: relative;
              cursor: pointer;
              font-size: 12px;
              line-height: 12px;
              color: #999;
              height: 33px;

              &:hover {
                color: $colorMain;

                &.has_more {
                  &:before {
                    border-top-color: $colorMain;
                  }
                }

                .li_item_more {
                  display: block;
                }
              }

              &.has_more {
                padding-right: 12px;

                &:before,
                &:after {
                  position: absolute;
                  right: -2px;
                  display: block;
                  width: 0;
                  height: 0;
                  content: " ";
                  border: 4px solid transparent;
                  border-radius: 2px;
                }

                &:before {
                  top: 3px;
                  border-top: 5px solid #888;
                }

                &:after {
                  top: 1px;
                  border-top: 5px solid #f7f7f7;
                }
              }

              .li_item_more {
                position: absolute;
                width: 80px;
                padding: 5px 3px;
                left: 50%;
                transform: translateX(-50%);
                top: 21px;
                background: #fff;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
                z-index: 999;
                display: none;

                &:before,
                &:after {
                  position: absolute;
                  top: -11px;
                  left: 50%;
                  transform: translateX(-50%);
                  -webkit-transform: translateX(-50%);
                  display: block;
                  content: " ";
                  width: 0;
                  height: 0;
                  border: 5px solid transparent;
                  border-bottom: 6px solid #dedede;
                }

                &:after {
                  top: -10px;
                  border-bottom: 6px solid #fff;
                }

                .li_item_more_item {
                  display: block;
                  text-align: center;
                  color: #999;
                  line-height: 12px;
                  padding: 8px 0;

                  &:hover {
                    color: $colorMain;
                  }
                }
              }
            }

            &:last-child {
              border-right-width: 0;
              padding-right: 0;
            }
          }
        }
      }
    }
  }
  .gradeLogo_img{
    width: 25px;
    height: 25px;
    margin-top: 4px;
  }
</style>
